import React from "react";
import { withTranslation } from "react-i18next";
import Signup from "../Signup";
import $ from "jquery";
import Link from 'gatsby-link';
//const DEFAULT_CURRENCY = "USD";
const DEFAULT_CURRENCY = "EUR";

class Pricing extends React.Component {
/*  state = {
    currency: this.props.currency || DEFAULT_CURRENCY
  };

  renderPrice = period => {
    const { t } = this.props;
    switch (this.state.currency) {
      case "EUR":
        return (
          <div>
           <span className="semibold">
              €{period === "monthly" ? "5,90" : "19,90"}
            </span>
            <span className="inline_block font16">
              /{t("component.pricing.month")}*
            </span>
             
            <span className="inline_block font16">
              /{t("component.pricing.month")}*
            </span>
          </div>
        );
     default:
        return (
          <div>
                          
            <span className="semibold">
              {period === "monthly" ? "5,90" : "19,90"}
            </span> 
            <span className="inline_block font16">
              €/{t("component.pricing.month")}*
            </span>
          
            <span className="inline_block font16">
              €/{t("component.pricing.month")}*
            </span>
            
          </div>
        );
    }
  };*/
/*  changeCurrency = currency => {
    this.setState({ currency });
  };
*/
  //show mobile pricing mobile
/*  showMobilePricing = () => {
    if ($(window).width() < 800) {
      $(".component-Pricing__mobile").addClass("visible");
      $(".component-Pricing__desktop, .pricing-CTA").addClass("hidden");
      $(".component-Pricing__mobile").removeClass("hidden");
    } else {
      $(".component-Pricing__mobile").removeClass("visible");
      $(".component-Pricing__mobile").addClass("hidden");
      $(".component-Pricing__desktop, .pricing-CTA").removeClass("hidden");
    }
  };

  componentWillUnmount() {
    $(window).off("resize", this.showMobilePricing);
  }

  componentDidMount() {
    $(window).on("resize", this.showMobilePricing);
    $(window).trigger("resize");
  } */

  render() {
    const { t, i18n } = this.props;
    let language = 'en';
    if(i18n.language && i18n.language !== 'en'){
      language = i18n.language;
    }
    const features = [
      t("component.pricing.value.features.1"),
      t("component.pricing.value.features.2"),
      t("component.pricing.value.features.3"),
      t("component.pricing.value.features.4")
    ];
    return (
      <>
      
      <section
        id="pricing"
        className="padding_top60 padding_bottom60 component-Pricing"
      >
        <div className="container nopadding">
          <h1 className="font42 light font_second dark_gray text-center">
            {t("component.pricing.title")}
          </h1>
         {/* <div className="font18 top30 text-center">
            {t("component.pricing.subtitle")}
          </div> */}
          <div className="row justify-content-center">
            {/* <div className="col-lg-6 padding_top50"> */}
            <div className="col-lg-10 padding_top50">
       {/*    {language === 'en' ? <h3 className="blue bottom30">
                {t("component.pricing.features")}
              </h3> : ''}
           {language === 'en' && features.map((feature, i) => (
                <div key={i} className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  <span>{feature}</span>
              </div>
              ))}*/}
          <React.Fragment>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Aloita laadukkaiden digitaalisten tarjousten lähetys jo kokeiluaikana!</span> */}
                  <span>{t("component.hinnasto.fi1")}</span>
              </div>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Et tarvitse luottokorttia. Ei piilotettuja kuluja. Voit peruuttaa tilauksen milloin vain.</span> */}
                 <span>{t("component.hinnasto.fi2")}</span>
              </div>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Kokeilun jälkeen kiinteä, edullinen hinnoittelu. <Link className="blue" to="/fi/hinnasto" >Katso hinnat  →</Link></span> */}
                  <span>{t("component.hinnasto.fi3a")} <Link className="blue" to={language === 'en'? "/pricelist":"/fi/hinnasto"} >{t("component.hinnasto.fi3b")}  →</Link></span>
              </div>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Tee omia tarjouspohjia - tai käytä valmiita. <a className="blue" href="/fi/mallikirjasto" >Katso tarjouspohjakirjasto  →</a></span> */}
                  <span>{t("component.hinnasto.fi4a")} <Link className="blue" to={language === 'en'? "/templatelibrary":"/fi/mallikirjasto"} >{t("component.hinnasto.fi4b")}  →</Link></span>
              </div>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Lähetä <strong>rajaton määrä tarjouksia</strong></span> */}
                  <span>{t("component.hinnasto.fi5")}</span>
              </div>
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  {/* <span>Kaikki ominaisuudet käytössäsi</span> */}
                  <span>{t("component.hinnasto.fi6")}</span>
              </div>
              {/*
              <div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}>
                  <i className="fa fa-check blue right50"></i>
                  <span>{t("component.hinnasto.fi7a")} <a className="blue" href="#choose">{t("component.hinnasto.fi7b")}</a></span>
              </div>
              */}
              </React.Fragment>
             
            </div> 
            {/* Desktop Price Copy */}
            {/* First Price */}
            <div className="col-lg-6 nopadding right_block component-Pricing__desktop">
               {/* <div className="component-Pricing__table bg_white top60">
                  <div className="component-Pricing__table__monthly row align-middle nomargin justify-content-between">
                    <div className="semibold">
                      <span className="semibold">Duuers Solo</span>
                    </div>
                    <div>
                      <span className="semibold">5,90</span>
                      <span className="inline_block font16">
                      €/{t("component.pricing.month")}*
                      </span>
                    </div>
                  </div>
                </div>  */}
                  
                  {/*Second Price*/}
                
                {/*  <div className="component-Pricing__table bg_white top60">
                    <div className="component-Pricing__table__monthly row align-middle nomargin justify-content-between">
                      <div className="semibold">
                        <span className="semibold">Duuers Sales Team</span>
                      </div>
                      <div>
                        <span className="semibold">19,90</span>
                        <span className="inline_block font16">
                        €/{t("component.pricing.month")}*
                        </span>
                      </div>
                    </div>
                  </div> 
                  <div className="opacity7 font12 top10">
              * {t("component.pricing.vat")}
            </div>*/}
              </div>                    
            
            {/* Desktop Price Copy -end*/}
            {/*
            <div className="col-lg-6 nopadding right_block component-Pricing__desktop">
              <div className="component-Pricing__table bg_white top60">
                <div className="component-Pricing__table__monthly row align-middle nomargin justify-content-between">
                  <div className="semibold">
                  {t("component.pricing.monthly")}
                  {/*
                    {t("component.pricing.monthly")}*}
                  </div>
                  {this.renderPrice(" /kk / käyttäjä")}
                </div>
                </div>
                {/*Second Price*}
               <div className="component-Pricing__table bg_white top60">
                 <div className="component-Pricing__table__monthly row align-middle nomargin justify-content-between">
                  <div className="semibold">
                   {t("component.pricing.yearly")}
                   {/*
                    {t("component.pricing.yearly")}*}
                  </div>
                  {this.renderPrice(" /kk / käyttäjä")}
                </div>
              {/*
                <div className="component-Pricing__table__annual row nomargin justify-content-between">
                  <div className="tag uppercase semibold text-center">
                    {t("component.pricing.tag")}
                  </div>
                  <div
                    <span className="semibold">
                      {t("component.pricing.yearly")}
                    </span>
                    <div className="font16 bg_red white padding_sides5 inline_block save">
                      {t("component.pricing.save")} 15%
                    </div>
                    <p className="font12 nomargin">
                      {t("component.pricing.yearly-bill")}{" "}
                      {this.renderYearlyPrice()}
                    </p>
                  </div>
                  {this.renderPrice("annual")}
                  </div>
              *
                </div>
                <div className="opacity7 font12 top10">
                  * {t("component.pricing.vat")}
                </div>
              </div>
            </div>
                */}
              {/* pricing on mobile */}
         {/* <div className="row component-Pricing__mobile">
            <div className="col-lg-12 nopadding top20 component-Pricing__mobile--block">
              <div className="padding_top25 padding_bottom45 block radius3 bg_white padding_sides60">
                <div className="blue uppercase semibold">
                <span className="semibold">Duuers Solo</span>
                </div>
                <div className="text-center bottom40 top40 font54 light dark_gray price">
                  <div>
                    <span className="semibold">5,90</span>
                    <span className="inline_block font16">
                      €/{t("component.pricing.month")}*
                    </span>
                  </div>
                </div>
                <div className="height1  bg_black opacity2 top40"></div>
                <div className="opacity7 font12">
                  * {t("component.pricing.vat")}
                </div>
              </div>
            </div>
          </div> */}
          {/* Adding second price*/}
         {/* <div className="row component-Pricing__mobile">
            <div className="col-lg-12 nopadding top20 component-Pricing__mobile--block">
              <div className="padding_top25 padding_bottom45 block radius3 bg_white padding_sides60">
                <div className="blue uppercase semibold">
                <span className="semibold">Duuers Sales Team</span>
                </div>
                <div className="text-center bottom40 top40 font54 light dark_gray price">
                  <div>
                    <span className="semibold">19,90</span>
                    <span className="inline_block font16">
                      €/{t("component.pricing.month")}*
                    </span>
                  </div>
                </div>
                <div className="height1  bg_black opacity2 top40"></div>
                <div className="opacity7 font12">
                  * {t("component.pricing.vat")}
                </div>
              </div>
            </div>
          </div> */}
          </div>
               {/*
          <div className="row component-Pricing__mobile">
            <div className="col-lg-12 nopadding top20 component-Pricing__mobile--block">
              <div className="padding_top25 padding_bottom45 block radius3 bg_white padding_sides60">
                <div className="row component-Pricing__mobile--row">
        
                  <div className="blue uppercase semibold">
                    {t("component.pricing.yearly")}
                  </div>
                  <div className="font16 bg_red white padding_sides25 padding_top5 padding_bottom5 inline_block">
                    <div className="font16">
                      {t("component.pricing.save")} 15%
                    </div>
                  </div>
              
                </div>
                
               <div className="text-center bottom40 top40 font54 light dark_gray price">
                  <div>
                    <span className="semibold">19,90</span>
                    <span className="inline_block font16">
                      €/{t("component.pricing.month")}*
                    </span>
                  </div>
               
                  <div className="font16">
                    600
                    <span className="inline_block font16">
                      €/{t("component.pricing.year")}
                    </span>
                  </div>
             
                </div>
                <div className="height1  bg_black opacity2 top40"></div>
                {/*<div className="opacity7 font12">
                  * {t("component.pricing.vat")}
                </div>
              </div>
            </div>*}
               </div>*/}

          <div className="row justify-content-center top40 bottom40">
            <div className="col-xl-4 col-lg-6">
              <Signup />
            </div>
          </div>
        </div>
      </section>
      </>
    );
  }
}
export default withTranslation()(Pricing);
