import React from 'react'
import { withTranslation } from 'react-i18next';

function Signup({className, t, i18n}){

  return(
    <div className="component-Signup nopadding text-center buttons">
      <div className={"component-Signup__wrapper"}>
        <div className={"component-Signup__normal radius6"}>
          <a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size60 padding_sides35 radius6 semibold">{t('component.hinnasto.listafree.start')}</a>
        </div>
      </div>
    </div>


  )
}

export default withTranslation()(Signup);
