import React from 'react'
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

class VideoSection extends React.Component {

	componentDidMount() {
		const { src } = this.props;
		// Open video in popup
		$(this.video).find('.play').click((event) => {
			event.preventDefault();
			$(this.video).find(".video_popup .iframe_container").html('<iframe src="' + src + '"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
			$(this.video).find(".video_popup, .overlay").fadeIn(300);
		});

		// Close video popup
		$(this.video).find(".video_popup .close, .overlay").click((e) => {
			$(this.video).find(".video_popup, .overlay").fadeOut(300);
			const videoPopup = $(this.video).find(".video_popup .iframe_container");
			setTimeout(function() {
				videoPopup.html('');
			}, 300);
		});
	}

	render() {
		const { poster, bg } = this.props;

		return (
        <section className={`content_24 padding_top95 padding_bottom95 component-VideoSection bg_${bg}`} ref={video => this.video = video}>
			<div className="container nopadding">
				<div className="max_width770 margin_auto radius8 video" data-ratio="16/9">
					<div className="radius8 poster" style={{backgroundImage:`url(${poster})`}}>
						<div className="play red"><i className="fa fa-play"></i></div>
					</div>
				</div>
			</div>
			<div className="overlay"></div>

			<div className="video_popup">
				<a className="close">
					<img srcSet="/i/close_white.png 1x, /i/close_white_2x.png 2x" src="i/close_white.png" alt="" />
				</a>
				<div className="flex align_center justify_center width_full height_full iframe_container"></div>
			</div>
		</section>);
	}
}

export default withTranslation()(VideoSection);
