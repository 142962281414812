import React from 'react'
import { withTranslation } from 'react-i18next';

class GetStarted extends React.Component {

	render() {
		const { t, 
	//	i18n
		} = this.props;

		return (
			<div className="component-GetStarted">
			<section id="getstarted" className="showcase_1 bg_white padding_top105 padding_bottom80">
			<div className="container nopadding text-center">
				<h2 className="font42 light font_second dark_gray">{t('component.getStarted.title')}</h2>
				<div className="margin_auto top25 max_width770 font18 light text">{t('component.getStarted.description')}</div>
				<div className="row cards_row">
					<div className="top65 text-left flex justify_between cards width_full">
						<div className="margin_auto bottom60 max_width370 block text-center">
							<div className="width_full text-center"><img srcSet="/images/duuers-1-3-x.png 1x, /images/duuers-1-3-x@2x.png 2x, /images/duuers-1-3-x@3x.png 2x" src="/images/duuers-2-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>
							<div className="top25 font18 semibold font_second dark_gray title">
								{t('component.getStarted.step1.title')}
							</div>
							<div className="top10 font16 light hyphens">
								{t('component.getStarted.step1.description')}
							</div>
						</div>
						<div className="margin_auto bottom60 max_width370 block text-center">
							<div className="width_full text-center"><img srcSet="/images/duuers-2-3-x.png 1x, /images/duuers-2-3-x@2x.png 2x, /images/duuers-2-3-x@3x.png 2x" src="/images/duuers-1-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>
							<div className="top25 font18 semibold font_second dark_gray title">
								{t('component.getStarted.step2.title')}
							</div>
							<div className="top10 font16 light hyphens">
								{t('component.getStarted.step2.description')}
							</div>
						</div>
						<div className="margin_auto bottom60 max_width370 block text-center">
							<div className="width_full text-center"><img srcSet="/images/duuers-3-3-x.png 1x, /images/duuers-3-3-x@2x.png 2x, /images/duuers-3-3-x@3x.png 2x" src="/images/duuers-3-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>
							<div className="top25 font18 semibold font_second dark_gray title">
								{t('component.getStarted.step3.title')}
							</div>
							<div className="top10 font16 light hyphens">
								{t('component.getStarted.step3.description')}
							</div>
						</div>
					</div>
				</div>
				
			</div> </section>
        </div>);
	}
};

export default withTranslation()(GetStarted);
