import React from 'react'
import { withTranslation } from 'react-i18next';
import $ from 'jquery';

class Video extends React.Component {

	componentDidMount() {
		const { src } = this.props;
		// Open video in popup
		$(this.video).find('.play').click((event) => {
			event.preventDefault();
			$(this.video).find(".video_popup .iframe_container").html('<iframe src="' + src + '"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
			$(this.video).find(".video_popup, .overlay").fadeIn(300);
		});

		// Close video popup
		$(this.video).find(".video_popup .close, .overlay").click((e) => {
			$(this.video).find(".video_popup, .overlay").fadeOut(300);
			const videoPopup = $(this.video).find(".video_popup .iframe_container");
			setTimeout(function() {
				videoPopup.html('');
			}, 300);
		});
	}

	render() {
		const { t, 
	//	i18n, 
		src, top, blue } = this.props;

		return (
			<div className={`${blue ? 'blue' : 'white'} justify_center flex ${top ? top : ''} video component-Video`} data-ratio="16/9" ref={video => this.video = video}>
			<a className={`play ${blue ? 'blue' : 'white'} semibold play_popup_video line_height42 flex align_center`} href={src}>
				<i className="fa fa-play-circle font42"></i>
				<span className="left10">{t('component.video.play')}</span>
			</a>
    		<div className="overlay"></div>

			<div className="video_popup">
        	<a className="close">
        		<img srcSet="/i/close_white.png 1x, /i/close_white_2x.png 2x" src="i/close_white.png" alt="" />
        	</a>
        	<div className="flex align_center justify_center width_full height_full iframe_container"></div>
        </div>
        </div>);
	}
};

export default withTranslation()(Video);
