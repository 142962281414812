import React from 'react'
import { withTranslation } from 'react-i18next';

function BookDemo({t, i18n}){
  return (<section id="bookdemo" className="call_to_action_13 bg_blue padding_top100 padding_bottom90">
	<div className="container nopadding text-center">
		<div className="max_width570 margin_auto white inner">
			<img src="/images/duuers_icon_clap2x.png" alt="logo"/>
			<h2 className="top40 font42 font_second light">{t('bookdemo.title')}</h2>
			<div className="top20 font18 light opacity7 text">
				{t('bookdemo.description')}
			</div>
		</div>
		
		<div className="top40 text-center">
			<button onClick={()=>window.Intercom('show')} rel="noopener noreferrer" target="_blank" className="btn white size50 padding_sides35 radius6 sides25 top45"><span className="blue">{t('bookdemo.cta')}</span></button>
		</div>
	</div>
</section>);
  }

export default withTranslation()(BookDemo);