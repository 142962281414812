/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = ({frontmatter}) => {
  const { author } = frontmatter

  const data = useStaticQuery(graphql`
    query BioQuery {
      JohnHills: file(absolutePath: { regex: "/john.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const authorStripped = author &&  author.replace(/\s/g, '');
  const image = data[authorStripped] && data[authorStripped].childImageSharp && data[authorStripped].childImageSharp.fixed
  return (
    <div
      style={{
        display: `flex`,
        alignItems: 'center'
      }}
    >
      { image && <Image
        fixed={image}
        alt={author}
        style={{
          marginBottom: 0,
          marginRight: '1em',
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      }
      <strong>{author}</strong>
    </div>
  )
}

export default Bio
