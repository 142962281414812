import React from 'react'
import { withTranslation } from 'react-i18next';

const Partner = ({t}) => (
  <div className={"container padding_top95 padding_bottom95"}>
    <div className="row justify-content-between justify-content-xl-start">
      <div className="col-lg-5" style={{alignSelf: 'center'}}>
        <img src="/images/featured/vastuu.png" className="width_full bg" alt=""/>
      </div>
      <div className="col-lg-6 offset-1">
        <h1 className="font_second light font42">{t('component.partner.title')}</h1>
        <div className="top30 font18 light dark_blue hyphens text">
          {t('component.partner.subtitle')}
        </div>
        <a className="top30 font16 link dark_black" target={"_blank"} href="https://blogi.tilaajavastuu.fi/tarjoukset-solmussa-duuers-helpottaa-tarjousten-tekemista">
          {t('component.partner.learnmore')}
        </a>
      </div>
    </div>
  </div>
);

export default withTranslation()(Partner);
