import React from 'react'
import Link from 'gatsby-link';
import { withTranslation } from 'react-i18next';

function Footer({t, i18n}){
    let language = 'en';
    if(i18n.language && i18n.language !== 'en'){
      language = i18n.language;
    }
    
    return (
        <footer className="footer_4 bg_white padding_top65 padding_bottom30">
        	<div className="container nopadding">
        		<div className="row">
        			<div className="col-xl-4 bottom20">
        				<img src="/images/duuers-logo.svg" className="height30" alt="logo"/>
        			</div>
        			<div className="col-xl-8 bottom35 text-xl-right top5 links">
        			    <Link className="font16 link dark_blue left35" to={`${language ? '/' + language : ''}/blog/`}>{t('footer.links.blog')}</Link>
                        <a className="font16 link dark_blue left35" href={`${language==='en' ? 'https://tuki.louhi.fi/support/solutions/folders/22000176467' : 'https://tuki.louhi.fi/support/solutions/articles/22000262367'}`} target="_blank" rel="noopener noreferrer">{t('footer.links.support')}</a>
                      
                        <a className="font16 link dark_blue left35" href={`${language==='en' ? 'https://www.louhi.fi/en/company/terms-and-conditions/' : 'https://www.louhi.fi/yritys/sopimusehdot/'}`} target="_blank" rel="noopener noreferrer">{t('footer.links.termsOfUse')}</a>
                        <a className="font16 link dark_blue left35" href={`${language==='en' ? 'https://www.louhi.fi/en/company/privacy-statement-of-the-customer-register/' : 'https://www.louhi.fi/yritys/rekisteriseloste//'}`} target="_blank" rel="noopener noreferrer">{t('footer.links.privacyPolicy')}</a>
                        
                        {/*
                        <a className="font16 link dark_blue left35" href="https://tuki.louhi.fi/support/home" target="_blank">{t('footer.links.support')}</a>
        			    <a className="font16 link dark_blue left35" href="https://medium.com/duuers/tarjouspohja-8328677b3f06">{t('footer.links.freeTemplate')}</a>
        			    <a className="font16 link dark_blue left35" href="https://tuki.louhi.fi/support/home" target="_blank">{t('footer.links.support')}</a>
        			    <a className="font16 link dark_blue left35" href="https://www.louhi.fi/yritys/avoimet-tyopaikat/" target="_blank">{t('footer.links.jobs')}</a>
                        */}
                        
                       {/*
                        <a className="font16 link dark_blue left35" href="https://www.louhi.fi/yritys/rekisteriseloste/" target="_blank">{t('footer.links.privacyPolicy')}</a>
                        <Link className="font16 link dark_blue left35" to={`/terms/`}>{t('footer.links.termsOfUse')}</Link>
                        <Link className="font16 link dark_blue left35" to={`/privacy/`}>{t('footer.links.privacyPolicy')}</Link>
                        */}
        			</div>
        		</div>
        		<div className="bottom40 hr height1 light_gray"></div>
        		<div className="max_width870 bottom15 float_left font16 light medium_gray footer_text">
                 Copyright © <a href={`${language==='en' ? 'https://www.louhi.fi/en/' : 'https://www.louhi.fi/'}`} target="_blank" rel="noopener noreferrer">Louhi Networks</a>

        		</div>
        		<div className="float_right socials">
        			<a href="https://www.instagram.com/Duuers_Insta/" className="font18 link medium_gray left30"><i className="fa fa-instagram"></i></a>
        			<a href="https://twitter.com/duuers" className="font18 link medium_gray left30"><i className="fa fa-twitter"></i></a>
        			<a href="https://www.facebook.com/duuers" className="font18 link medium_gray left30"><i className="fa fa-facebook-square"></i></a>
        			<a href="https://www.linkedin.com/company/16203578" className="font18 link medium_gray left30"><i className="fa fa-linkedin-square"></i></a>
        		</div>
        		<div className="clearfix"></div>
        	</div>
        </footer>
      );
  }

export default withTranslation()(Footer);
