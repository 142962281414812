import React from 'react'
import { withTranslation } from 'react-i18next';

function TextPage({t, title, children}){
  return(

    <section className="content_11 bg_white padding_top80 padding_bottom50 component-TextPage">
	    <div className="container nopadding max_width970">
		    <div className="dark_blue light font18 text">
    			<h2 className="max_width570 margin_auto font_second light font28">{title}</h2>
    			<div className="max_width570 margin_auto top25 font18 light dark_blue text">
    				{children}
    			</div>
    		</div>
    	</div>
    </section>
    
    )
}
  
export default withTranslation()(TextPage);
