import React from 'react'
import { withTranslation } from 'react-i18next';

function SignupDivider({className, t, i18n}){
  return(
    
    <section className={`call_to_action_13 bg_blue padding_top50 padding_bottom50 ${className}`}>
    	<div className="container nopadding max_width970">
    		<div className="row align-items-center">
    			<div className="col-lg-6">
    				<h2 className="font28 font_second light white">{t('component.signup-divider.title')}</h2>
    			</div>
    			<div className="col-lg-6 text-lg-right">
    				<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size50 padding_sides35 radius6 green top25">{t('component.hinnasto.listafree.start')}</a>
    			</div>
    		</div>
    	</div>
    </section>
    )
}
  
export default withTranslation()(SignupDivider);
