import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "gatsby";

class BlogReferenssit extends React.Component {
  render() {
    const { t, i18n, posts, bg } = this.props;
    let language = "en";
    if (i18n.language && i18n.language !== "en") {
      language = i18n.language;
    }
    return (
      <section
        className={`component-blog-referenssit content_34 padding_top60 padding_bottom60 bg_${bg}`}
      >
        <div className="container nopadding">
         {/* <h1 className="font42 light font_second dark_gray text-center">
            {t("component.blog-section.title")}
          </h1>
          <div className="font18 top30 text-center">
            {t("component.blog-section.subtitle")}
          </div>*/}
          <div className="row">
            {posts.map((post, i) => (
              <div className="col-md-4 top40" key={i}>
                <div className="card h-100">
                  <img
                    className="card-img-top"
                    src={post.node.frontmatter.hero}
                    alt="duuers blog"
                  />
                  <div className="card-body">
                    <div className="card-title semibold font18">
                      {post.node.frontmatter.title}
                    </div>
                    <p className="card-text">
                      {post.node.frontmatter.description}
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="height1  bg_blue opacity2 bottom10 card--divider"></div>
                    <a
                      href={`/${post.node.fields.path}`}
                      className="stretched-link blue regular"
                    >
                      {t("component.blog-section.card-button")}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="top30 radius6 text-center">
            <Link
              className="btn blue size60 padding_sides35 radius6 semibold"
              to={`${language ? language : ""}/blog/`}
            >
              {t("component.blog-section.cta-button")}
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(BlogReferenssit);
