import React from 'react'
import { withTranslation } from 'react-i18next';
import Video from '../Video';

function FeatureTestimonial({ invert, srcSet, src, title, description, t, i18n, video, linkkiteksti, linkkiurl, phone, sectionid, googlelomake, osoite, target, buttontext }) {
  return (<section id={sectionid} className={`feature_26 ${invert ? '' : 'bg_light_blue component-FeatureTestimonial--invert'} padding_top120 padding_bottom100 component-FeatureTestimonial`}>
      	<div className="container nopadding">
      		<div className="row justify-content-between justify-content-xl-start">
      			<div className={`col-lg-5 ${invert ? '' : 'offset-1'}`}>
      				<img srcSet={srcSet} src={src} className="width_full bg" alt="" />	
      			</div>
      			<div className={`col-lg-6 ${invert ? 'offset-1' : ''}`}>
      				<div className="max_width600 inner">
      					<h1 className="font_second light font42">{title}</h1>
      					<div className="top30 font18 light dark_blue hyphens text">{description}</div><br />
      					{osoite && <div className="top30"><span>{osoite}</span></div>}
      					{phone && <div className="top30 font18 light dark_blue hyphens text"><i className="fa fa-phone blue"></i>&nbsp;{phone}</div>}
      					{/* <div className="font18 light dark_blue hyphens text">{phone}</div><br />*/}
      				  {linkkiurl &&	<div className="top30"><a className="blue" href={linkkiurl} target={target} rel="noopener noreferrer">{linkkiteksti}</a></div> }
      					
      					{googlelomake && <div className="top30"><a rel="noopener noreferrer" target="_blank" href={googlelomake} className="btn blue size60 padding_sides35 radius6 top30 semibold width_full"><span className="white">{buttontext}</span></a></div>}
      				 
      				</div>
      				{video &&
        				<div className="component-FeatureTestimonial__video top40 padding_sides40 padding_top40 padding_bottom30">
          				<div className="row">
          				  <div className="col-xl-4 flex align-center justify-content-center">
          				    <img srcSet={video.srcSet} src={video.src} className="width140 height140 radius_full bottom30" alt="" />	
        		        </div>
        		        <div className="col-xl-8 padding_left40">
        		          <h3>{video.title}</h3>
        		          <h6>{video.description}</h6>
        		          <Video src={video.href}/>
          				  </div>
          				</div>
        				</div>
      				}
      			</div>
      		</div>
      	</div>
      </section>);
}

export default withTranslation()(FeatureTestimonial);
