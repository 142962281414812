import React from 'react'

const HeroPlain = ({ children, background, style }) => (
	<div className="padding_top235 padding_bottom160 min_height800 component-HeroPlain" style={{backgroundImage: background ? `url(${background})` : null, ...style}}>
	  <div className={"container-fluid line_height50"}>
		  <div className={"row"}>
			  <div className="col-xl-12 text-center">
				  {children}
			  </div>
		  </div>
	  </div>
	</div>
);

export default HeroPlain
