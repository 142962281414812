import React from 'react'
import { withTranslation } from 'react-i18next';

const DownloadCTA = ({logos, t, i18n}) => (
  <div className="DownloadCTA nopadding text-center buttons">
    <div className={"DownloadCTA__wrapper"}>
      <a href={`https://www.duuers.com/?lng=${i18n.language}`} className="btn size60 white semibold uppercase radius6 DownloadCTA__free">
        <span>{t('page.download.cta')}</span>
      </a>
    </div>
  </div>
);

export default withTranslation()(DownloadCTA);
