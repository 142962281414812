import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "gatsby";

class BlogCTA extends React.Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <div className="component-blog-cta">
        <img
          src="/images/duuers-logo.svg"
          className="height30 component-blog-cta__logo"
          alt="logo"
        />
        <h3 className="top20 text-center component-blog-cta__header">
          {t("blog.cta-header")}
        </h3>
        <div className="row cards_row">
          <div className="top30 text-left flex justify_between cards width_full">
            <div className="col-lg-4 bottom60 block text-center">
              <div className="width_full text-center">
                <img
                  src="/images/learn-more.png"
                  className="max_width370 max_height150"
                  alt=""
                />
              </div>
              <div className="top25">
                {/* <Link
                  to={`/${i18n.language === "en" ? "" : "fi"}`}
                  className="btn semibold blue radius6"
                > */}
                <a
                  href={`${i18n.language === "en" ? "https://www.youtube.com/watch?v=mKfd15DuqLE&t=2s" : "https://www.youtube.com/watch?v=23hKTzrJLPQ&t=3s"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn semibold blue radius6 sides25"
                >
                  <div className="padding_top10 padding_bottom10">
                  {t("blog.cta-learnmore")}
                  </div>
                </a>
               {/* </Link> */}
              </div>
            </div>
            <div className="col-lg-4 bottom60 block text-center">
              <div className="width_full text-center">
                <img
                  src="/images/book-demo.png"
                  className="max_width370 max_height150"
                  alt=""
                />
              </div>
              <div className="top25">
                {/* <button
                  onClick={() => window.Intercom('showNewMessage', t('blog.cta-demo-message'))}
                  className="btn semibold blue size50 padding_sides35 radius6 sides25"
                >
                  {t("blog.cta-bookdemo")}
                </button> */}
                <a
                  href={`${i18n.language === "en" ? "https://forms.gle/kXLqMZ7J9eaLZAhb7" : "https://forms.gle/iWFXUeMTPtWKhGmg9"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn semibold blue radius6 sides25"
                >
                <div className="padding_top10 padding_bottom10">
                  {t("blog.cta-bookdemo")}
                </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 bottom60 block text-center">
              <div className="width_full text-center">
                <img
                  src="/images/free-template.png"
                  className="max_width370 max_height150"
                  alt=""
                />
              </div>
              <div className="top25">
                {/* <button
                  onClick={() => window.Intercom('showNewMessage', t('blog.cta-template-message'))}
                  className="btn semibold blue size50 padding_sides35 radius6 sides25"
                >
                  {t("blog.cta-template")}
                </button> */}
                <a
                  href={`${i18n.language === "en" ? "https://forms.gle/oyJaFnUp96jELPu18" : "https://forms.gle/ZVNJctpxk8PkKK9X7"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn semibold blue radius6 sides25"
                >
                <div className="padding_top10 padding_bottom10">
                  {t("blog.cta-template")}
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BlogCTA);
