import React from 'react'
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import 'slick-carousel';

export function Logo(props){
  const {image1, image2 } = props
  return(
    <div className="padding_top0 component-Logo padding_sides0">
  		<div className="component-Logot__slide">
  			{image1 && image2 &&
  			  <div className="component-Logo__image" >
    			  <img src={image1} alt="" />
    			  <img src={image2} alt="" />
  			  </div>
  			}
  		</div>
  	</div>
  )
}

class Logot extends React.Component {
  componentDidMount() {
    $(".content_31 .slider").slick({
      dots: false,
      arrows: true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [{
        breakpoint: 991,
        settings: {
          arrows: false
        }
      }]
    });
  }
  render() {
    const { children } = this.props;
    return (
      <section  id="logot" className="content_31 padding_top40 padding_bottom30 component-Logot ">
      	<div className="radius18 top0 padding_bottom0 container padding_top0">
      		<div className="top0 slider arrows0 dots_dark_blue dots-50">
      			{children}
      		</div>
      	</div>
      </section>);
  }
}

export default withTranslation()(Logot);
