import React from 'react'
import { withTranslation } from 'react-i18next';

export function Feature(props){
  const { src, srcSet, bg='white', reverse, name, descriptions } = props;
  return (<section className={`feature_36 padding_top110 padding_bottom110 bg_${bg}`}>
		<div className="container nopadding dark_gray">
			<div className={`row align-items-center ${reverse ? 'reverse' : ''}`}>
				<div className="col-xl-1 hide_at_1200"></div>
				<div className="col-xl-5 col-lg-6 col-md-7 padding_bottom40 inner">
					<h2 className="bottom30 font42 font_second light">{name}</h2>
					{
						descriptions.map((description, i) => <div key={i} className="bottom40 flex align-items-center advantage"><div className="font18"><i className="fa fa-check blue font24 right20"></i>{description}</div></div>)
					}
				</div>
				<div className="col-xl-1 hide_at_1200"></div>
				<div className="flex justify-content-center justify-content-md-end align-items-center col-xl-5 col-lg-6 col-md-5">
					<img srcSet={srcSet} src={src} className="max_width_full bg" alt=""/>
				</div>
			</div>
		</div>
	</section>);
}

function Features({title, subtitle, children, t}){
  return (
		<section id="features" className="showcase_1 bg_white padding_top140 padding_bottom100 component-Features">
			<div className="container nopadding text-center">
				<h2 className="font42 light font_second dark_gray">{title || t('component.features.title')}</h2>
				<div className="margin_auto top25 max_width770 font18 light text">
        			{subtitle || t('component.features.subtitle')}
				</div>
				<div className="row cards_row">
					<div className="top65 text-left flex justify_between cards">
					{
						children && children
					}
					{
						!children && 
						<React.Fragment>
							<Feature src="/images/features/proposal.png" name={t('component.features.contentLibrary.name')} description={t('component.features.contentLibrary.description')}/>
							<Feature src="/images/features/messages.png" name={t('component.features.messages.name')} description={t('component.features.messages.description')}/>
							<Feature src="/images/features/proposalarchive.png" name={t('component.features.archive.name')} description={t('component.features.archive.description')}/>
							<Feature src="/images/features/mobile-friendly.png" name={t('component.features.mobile.name')} description={t('component.features.mobile.description')}/>
						</React.Fragment>
					}
					</div>
				</div>
			</div>
		</section>
  );
}

export default withTranslation()(Features);
