import React from 'react'
//import Link from 'gatsby-link';
import { withTranslation } from 'react-i18next';

function Location({ t, language }) {

    return (
        <section className="contact_2 bg_white padding_top25 padding_bottom70">
			<div className="container nopadding max_width970 dark_blue text-center">
				<h2 className="top20 font42 font_second light">{t('component.location.title')}</h2>
				<div className="max_width770 margin_auto top35 font22 light dark_blue hyphens text">
					{t('component.location.description')}
				</div>
				<div className="row top85">
					<div className="col-md-6 bottom50">
						<img src="/images/Helsinki.png" className="width200" alt=""/>
						<div className="font_second semibold font18 dark_gray title">Finland</div>
						<div className="max_width270 margin_auto top10 font16 light">
							Vaisalantie 8 D
							<br/>
							02130 Espoo
						</div>
					</div>
					<div className="col-md-6 bottom50">
						<img src="/images/Toronto.png" className="width200" alt=""/>
						<div className="font_second semibold font18 dark_gray title">Canada</div>
						<div className="max_width270 margin_auto top10 font16 light">
							410 Adelaide Street West <br/>Toronto M5V 1S8
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default withTranslation()(Location);
