import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CookieBanner from 'react-cookie-banner';
import { withTranslation } from 'react-i18next';
import Header from '../components/Header';
import '../styles/style.scss';

class LandingLayout extends React.Component {

  componentDidMount() {
    if (this.props.language) {
      this.props.i18n.changeLanguage(this.props.language);
    }
  }

  render() {
    const { t, title, meta, link, children } = this.props;
    return (<div>
    <CookieBanner 
      disableStyle={true}
      buttonMessage="OK" 
      link={{msg: t('cookie.policy.link'), url: '/privacy'}}
      message={t('cookie.policy.title')}
      onAccept={() => {}}
      cookie='user-has-accepted-cookies'/>
    <Helmet
      title={title}
      meta={meta}
      link={link}
    >
    </Helmet>
    <Header/>
    {children}
  </div>)
  }
};

LandingLayout.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
  link: PropTypes.array,
  language: PropTypes.string
};

export default withTranslation()(LandingLayout);
