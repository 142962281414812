import React from 'react'
import { withTranslation } from 'react-i18next';

function Templatethumbs({template=[], t, i18n}){
  return (<section className="content_34 padding_top60 padding_bottom60 component-Templatethumb">
	<div className="container nopadding">
		{/*<div className="text-center font14 uppercase semibold dark_blue spacing20">{t('component.featuredIn.title')}</div>*/}
		<div className="flex justify_center align_center margin_auto max_width1030 top20">
			{
				template.map((template, i) => (
				<div key={i} className="margin_auto text-center top30" style={{'alignItems': 'end', 'maxWidth': '300px'}}>
					<img srcSet={template.srcSet} src={template.src} alt="" />
				</div>
				))
			}
		</div>
	</div>
</section>);
  }

export default withTranslation()(Templatethumbs);