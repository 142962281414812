import React from 'react'
import Link from 'gatsby-link';
//import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import { AnchorLink } from "gatsby-plugin-anchor-links";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpenRight: false,
      sticky: false,
      menuVisible: false,
      headerNavVisible: false,
      navigationMobile: false,
      width:1200
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
   // this.toggle = this.toggle.bind(this);
    this.toggleRight = this.toggleRight.bind(this);
    this.makeNavFollow = this.makeNavFollow.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  /*toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }*/
  open = () => {
    this.setState({
      isOpen: true,
      isOpenRight: false
    });
  }
  close = () => {
    this.setState({
      isOpen: false,
      isOpenRight: false
    });
  }
  toggleRight = () => {
    this.setState({
      isOpenRight: !this.state.isOpenRight
    });
  }

  // open menu on mobile
 /* showMenuBtn = () => {
   // if ($(window).width() < 1200) {
   if ($(window).width() < 1200) {
      $(".open_menu").addClass("visible");
      $("header nav").addClass("hidden");
      $(".navigation_mobile").removeClass("opened");
    }
    else {
      $(".open_menu").removeClass("visible");
      $("header nav").removeClass("hidden");
      $(".navigation_mobile").removeClass("opened");
    }
  }*/

  makeNavFollow = () => {
    if(!this.header){
      return;
    }
    const sticky = window.pageYOffset > this.header.offsetTop;
    this.setState({sticky});
  }
  
  /**
 * Calculate & Update state of new dimensions
 */
  updateDimensions = () => {
     if (window.innerWidth < 1200) {
      this.close();
      this.setState({
        menuVisible: true,
        headerNavVisible: false,
        navigationMobile: false
      });
      //$(".open_menu").addClass("visible");
      //$("header nav").addClass("hidden");
      //$(".navigation_mobile").removeClass("opened");
    }
    else {
      this.close();
      this.setState({
        menuVisible: false,
        headerNavVisible: true,
        navigationMobile: false
      });
      //$(".open_menu").removeClass("visible");
      //$("header nav").removeClass("hidden");
      //$(".navigation_mobile").removeClass("opened");
    }
   
  }


  componentDidMount() {
    // $(window).on('resize', this.showMenuBtn.bind(this));
    // $(window).on('scroll', this.makeNavFollow.bind(this));
    // $(window).trigger("resize");
    
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("scroll", this.makeNavFollow);
    
    
   /* $(".open_menu").click(function() {
      $(".navigation_mobile").addClass("opened");
    });
    
   // $(".close_menu, header, section, .navigation_mobile .inner a").click(function() {
      $(".navigation_mobile").removeClass("opened");
    });*/
  }
  
  
   // componentWillUnmount() {
    // $(window).off('resize', this.showMenuBtn.bind(this));
    // $(window).off('scroll', this.makeNavFollow.bind(this));
    
    //window.removeEventListener("resize", this.close);
   // window.removeEventListener("scroll", this.makeNavFollow);
    
 // }

  render() {
    const {t, i18n, alwaysSticky} = this.props;
    let language = 'en';
    if(i18n.language && i18n.language !== 'en'){
      language = i18n.language;
    }
    const about = language === 'fi' ? 'yritys' : 'about';
    const features = language === 'fi' ? 'tarjoustyokalu' : 'proposal-software';
    return (
      <div className={`component-Header ${this.state.sticky || alwaysSticky ? 'component-Header__sticky' : ''}`} ref={header => this.header = header}>
        {/*<div className={`open_menu left ${this.state.menuVisible ? 'visible' : ''}`} onClick={this.open}>
          <img src="/images/duuers-logo.png" className="height20" alt=""/>
        </div>*/}
        <div className={`open_menu left ${this.state.menuVisible ? 'visible' : ''}`}>
          <Link to={`/${i18n.language === 'en' ? '' : i18n.language}`}><img src="/images/duuers-logo.png" className="height20" alt=""/></Link>
        </div>
       
          {/*<a className={`open_menu ${this.state.menuVisible ? 'visible' : ''}`} href="#" onClick={this.open}><i className="fa fa-bars"></i></a>*/}
          <span className={`open_menu ${this.state.menuVisible ? 'visible' : ''}`} onClick={this.open} style={{cursor:"pointer"}}><i className="fa fa-bars"></i></span>
          <div className={`navigation_mobile type4 bg_white ${this.state.isOpen ? 'opened' : ''}`}>
          
          {/*	<a className="close_menu blue" href="#" onClick={this.close}><i className="fa fa-close"></i></a> */}
            <span className="close_menu blue" style={{cursor:"pointer"}} onClick={this.close} ><i className="fa fa-close"></i></span>
          	<div className="padding_top30 padding_bottom60 inner">
          		<a href={`https://app.duuers.com/auth/login?lng=${i18n.language}`} className="top20 left40 btn size40 transparent_blue border radius6 spacing15 font12 padding_sides20 semibold">{t('menu.login')}</a>
          		<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="top20 left40 bottom40 btn size40 blue border radius6 spacing15 font12 padding_sides20 semibold">{t('menu.signup')}</a>
            
            {language !== 'en' &&	<> 
            <div className="hr height1 blue opacity1"></div>
            
            <div 
                className="unfold_menu_right left40 font_second font20 line_height70 link blue lg-font10" style={{cursor:"pointer"}}
                //to={`/${language === 'en' ? '' : language}/`}
                activeClassName="blue" 
                onClick={this.toggleRight}
                >
                Tarjoustyökalu&nbsp;
                  <i 
                    className={this.state.isOpenRight ? "fa fa-angle-up" : "fa fa-angle-down"}
                  >
                  </i>
            </div>
                    <div className={`component-Header__menuright ${this.state.isOpenRight ? "unfold-menu" : "fold-menu"}`}>
            				  <ul>
            		        <li><Link className="link bottom10" to={`${language === 'en' ? '' : '/' + language}/${features}`} onClick={this.close} activeClassName="blue">Ominaisuudet</Link></li>
            		       {/* <li><Link className="link bottom10" to={`${language === 'en' ? '' : '/' + language}/#faster`} onClick={this.close} activeClassName="blue">Hyvä tarjous - 10x nopeammin</Link></li>*/}
            		        <li><AnchorLink className="link bottom10" to={`${language === 'en' ? '' : '/' + language}#faster`} onAnchorLinkClick={this.close} activeClassName="blue">Hyvä tarjous - 10x nopeammin</AnchorLink></li>
            		        <li><Link className="link bottom10" to={`${language === 'en' ? '' : '/' + language}/blog/tarjouksen-tekeminen/seuraa-tarjouksen-etenemista`} activeClassName="blue">Tarjouksen kulku - seuraa etenemistä</Link></li>
            		        <li><Link className="link bottom10" to={`${language === 'en' ? '' : '/' + language}/blog/tarjouksen-tekeminen/taydellinen-tarjous`} onClick={this.close} activeClassName="blue">Digitaalinen tarjous - asiakkaan ehdoilla</Link></li>
            		        <li><Link className="link bottom10" to={`${language === 'en' ? '' : '/' + language}/blog/yrittajyys/5-syyta-kayttaa-duuersia`} onClick={this.close} activeClassName="blue">5 syytä ottaa Duuers käyttöön</Link></li>
            		      </ul>
            		    </div>
        		    </>
    		      }


          		{language === 'en' && <><div className="hr height1 blue opacity1"></div>
          		<Link className="left40 font_second font20 line_height70 link blue" to={`/${language}/${features}`}>{t('menu.howitworks')}</Link></>}
          		<div className="hr height1 blue opacity1"></div>
      		{/*<Link className="left40 font_second font20 line_height70 link blue" to={`/${language === 'en' ? '' : language}/#references`}>{t('menu.references')}</Link>*/}
          		{language === 'en' && <>	<div className="hr height1 blue opacity1"></div>
              <Link className="left40 font_second font20 line_height70 link blue" to={`/pricelist`}>Pricing</Link></>}
          		{language !== 'en' &&	<> 
            	<Link className="left40 font_second font20 line_height70 link blue" to={`/${language}/hinnasto`}>Hinnasto</Link>
          		</>}
          		{language !== 'en' &&	<> 
          		<div className="hr height1 blue opacity1"></div>
          {/*		<Link className="left40 font_second font20 line_height70 link blue" to={`/${language === 'en' ? '' : language}/#pricing`}>{t('menu.pricing')}</Link>*/}
          		<Link className="left40 font_second font20 line_height70 link blue" to={`/${language}/referenssitarinat`}>Referenssit</Link>
          		</>}
              <div className="hr height1 blue opacity1"></div>
              <Link className="left40 font_second font20 line_height70 link blue" to={`/${language}/${about}`}>{t('menu.about')}</Link>
              <div className="hr height1 blue opacity1"></div>
              <Link className="left40 font_second font20 line_height70 link blue" to={'/' + language + '/blog'}>{t('menu.blog')}</Link>
              <div className="hr height1 blue opacity1"></div>
              {/*<Link className="left40 font_second font20 line_height70 link blue" to={`/${language === 'en' ? '#choose': language + '/#choose'}`} onClick={this.close} >{t('menu.contact')}</Link>*/}
          		<AnchorLink className="left40 font_second font20 line_height70 link blue" to={`${language === 'en' ? '#choose': language + '#choose'}`} onAnchorLinkClick={this.close} >{t('menu.contact')}</AnchorLink>
          		<div className="left40 top40 links">
  		          <Link className="link blue light" to={'/'} onClick={()=>{i18n.changeLanguage('en')}}>EN</Link>
  		          <Link className="link blue light sides10" to={`/fi`} onClick={()=>{i18n.changeLanguage('fi')}}>FI</Link>
          		</div>

          	{/*	<div className="left40 top40 links">
                <div><a className="font12 semibold link opacity3 spacing15 bottom20" href={`${language==='en' ? 'https://www.louhi.fi/en/company/terms-and-conditions/' : 'https://www.louhi.fi/yritys/sopimusehdot/'}`} target="_blank" rel="noopener noreferrer">{t('footer.links.termsOfUse')}</a></div>
                <div><a className="font12 semibold link opacity3 spacing15 bottom20" href={`${language==='en' ? 'https://www.louhi.fi/en/company/privacy-statement-of-the-customer-register/' : 'https://www.louhi.fi/yritys/rekisteriseloste//'}`} target="_blank" rel="noopener noreferrer">{t('footer.links.privacyPolicy')}</a></div>
          		</div> */}
          	</div>
          </div>


          <header className={`${this.state.menuVisible ? 'padding_top85' : 'padding_top120'}`}>
          	<nav className={`navigation_16 transparent padding_top10 padding_bottom15 ${this.state.headerNavVisible ? "" : "hidden"}`}>
          		<div className="container-fluid nopadding line_height50">
          			<Link to={`/${i18n.language === 'en' ? '' : i18n.language}`} className="logo left120 right20 float_left"></Link>
          				<div className="row float_right">
          			  	<div className="col-xl-12 text-right padding_right80">
                				<div className="">
                          		      <Link className="link semibold sides5" to={'/'} onClick={()=>{i18n.changeLanguage('en')}}>en</Link>
                          		      <span className="">|</span>
                          		      <Link className="link semibold sides5" to={`/fi`} onClick={()=>{i18n.changeLanguage('fi')}}>fi</Link>
                       
              		      <Link className="left20 link semibold" to={'/' + language + '/blog'} activeClassName="blue">{t('menu.blog')}</Link>
              		      
                      	{/*<Link className="left20 link semibold" to={`/${language === 'en' ? '#choose': language + '/#choose'}`} activeClassName="blue">{t('menu.contact')}</Link>*/}
              		      <AnchorLink className="left20 link semibold" to={`${language === 'en' ? '#choose': language + '#choose'}`} activeClassName="blue" >{t('menu.contact')}</AnchorLink>
              					<a href={`https://app.duuers.com/auth/login?lng=${i18n.language}`} className="link semibold left20">{t('menu.login')}</a>
              				{/*	<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size50 padding_sides10 radius6 green semibold left20">{t('menu.signup')}</a> */}
          				     </div>
          				   </div>  
          				</div>
          				
          				
          				<div className="padding_left160 row float_left top5 width_full">
          					<div className="col-xl-10 text-right">
                				<ul className="component-Header__menu float_left">
                				{language === 'en' &&	<Link className="left20 link semibold uppercase" to={`/${language}/${features}`} activeClassName="blue">{t('menu.howitworks')}</Link>}

                  			{language !== 'en' &&	<li>          				  
                  				<Link className="left20 link semibold uppercase" to={`/${language}/`} activeClassName="blue">Tarjoustyökalu</Link>
                  				  <ul className="submenu">
                  		        <li>
                  		          <Link className="link semibold" to={`/${language}/${features}`} activeClassName="blue">Ominaisuudet</Link>
                  		        </li>
                  		        <li>
                  		          {/*<Link className="link semibold" to={`/${language}/#faster`} activeClassName="blue">Hyvä tarjous - 10x nopeammin</Link>*/}
                  		          <AnchorLink className="link semibold" to={`/${language}#faster`} activeClassName="blue" >Hyvä tarjous - 10x nopeammin</AnchorLink>
                  		        </li>
                  		        <li>
                  		          <Link className="link semibold" to={`/${language}/blog/tarjouksen-tekeminen/seuraa-tarjouksen-etenemista`} activeClassName="blue">Tarjouksen kulku - seuraa etenemistä</Link>
                  		        </li>
                  		        <li>
                  		          <Link className="link semibold" to={`/${language}/blog/tarjouksen-tekeminen/taydellinen-tarjous`} activeClassName="blue">Digitaalinen tarjous - asiakkaan ehdoilla</Link>
                  		        </li>
                  		        <li>
                  		          <Link className="link semibold" to={`/${language}/blog/yrittajyys/5-syyta-kayttaa-duuersia`} activeClassName="blue">5 syytä ottaa Duuers käyttöön</Link>
                  		        </li>
                  		      </ul>
                		      </li>}
                		      
                		      <li>
          		              <Link className="left20 link semibold uppercase" to={`${language === 'en' ? '': '/' + language}/${language === 'en' ? 'pricelist' : 'hinnasto'}`} activeClassName="blue">{`${language === 'en' ? 'Pricing' : 'Hinnasto'}`}</Link>
                  		    </li>
                  		    
                		     {/* <li>
          		             <Link className="left10 link semibold uppercase" to={`/${language}/${features}`} activeClassName="blue">{t('menu.howitworks')}</Link>
          		            </li> 
                  		    */}
                  		    {language !== 'en' && 
          		            <li>
                  		      <Link className="left20 link semibold uppercase" to={`/${language}/referenssitarinat`} activeClassName="blue">Referenssit</Link>
                  		    </li>
                  		    }
                  		    <li>
          		             <Link className="left20 link semibold uppercase" to={`/${language}/${about}`} activeClassName="blue">{t('menu.about')}</Link>
          		             <a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size40 padding_sides35 radius6 green semibold left60">
          		            {/* Aloita kokeilu*/}
          		            {t('component.hinnasto.listafree.start')}
          		             {/*{t('menu.signup')}*/}
          		             </a>
                  		    </li>
                  		    
                		    </ul>

          		    
          		    {/*  <Link className="link semibold sides20" to={`/${language}/${features}`} activeClassName="blue">{t('menu.howitworks')}</Link> */}
          		      
          		 {/* <Link className="link semibold sides20" to={`/${language === 'en' ? '' : language}/#references`} partiallyActive={true} activeClassName="blue">{t('menu.references')}</Link>*/}
          		     {/* <Link className="link semibold sides20" to={`/${language === 'en' ? '' : language}/#pricing`} partiallyActive={true} activeClassName="blue">{t('menu.pricing')}</Link> */}
          		    
          		  {/*    <Link className="link semibold sides20" to={`/${language}/hinnasto`} partiallyActive={true} activeClassName="blue">Hinnasto</Link> */}
                {/*    <Link className="link semibold sides20" to={`/${language}/${about}`} activeClassName="blue">{t('menu.about')}</Link> */}
                 {/*   <Link className="link semibold sides20" to={`/${language}/blog`} activeClassName="blue">{t('menu.blog')}</Link>*/}
                    
          				</div>
          				
          			</div>
          		</div>
          	</nav>
          </header>
      </div>
    )

  }
}

export default withTranslation()(Header);
