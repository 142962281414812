import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import HeroPlain from './HeroPlain';
import Features, {Feature} from './Features';
import Pricing from './Pricing';
import SignupDivider from './SignupDivider';
import TextPage from './TextPage';
import References, {Reference} from './References';
import BookDemo from './BookDemo';
import Video from './Video';
import FeaturedIn from './FeaturedIn';
import FeatureTestimonial from './FeatureTestimonial';
import Signup from './Signup';
import GetStarted from './GetStarted';
import DownloadCTA from './DownloadCTA';
import VideoSection from './VideoSection';
import Partner from './Partner';
import Bio from './Bio';
import Location from './Location';
import SEO from './SEO';
import BlogCTA from './BlogCTA';
import StickyCTA from './StickyCTA';
import BlogSection from './BlogSection';
import Hinnasto from './Hinnasto';
import HeroPricing from './HeroPricing';
import BlogReferenssit from './BlogReferenssit';
import Pricelist from './Pricelist';
import Templatethumbs from './Templatethumbs';
import Logot, {Logo} from './Logot';

export {
    Footer,
    Header,
    Hero,
    Location,
    HeroPlain,
    Features,
    Feature,
    Pricing,
    SignupDivider,
    TextPage,
    References,
    Reference,
    BookDemo,
    Video,
    FeaturedIn,
    Partner,
    FeatureTestimonial,
    Signup,
    DownloadCTA,
    GetStarted,
    VideoSection,
    Bio,
    SEO,
    BlogCTA,
    StickyCTA,
    BlogSection,
    Hinnasto,
    HeroPricing,
    BlogReferenssit,
    Pricelist,
    Templatethumbs,
    Logot,
    Logo
};
