import React from 'react'

const Hero = ({children}) => (
  <div className="padding_top160 padding_bottom160 min_height800 header_2 component-Hero">
	  <div className={"container-fluid line_height50"}>
		  <div className={"row"}>
			  <div className="col-xl-5 text-center">
				  <div className={"component-Hero__section"}>
					  {children}
				  </div>
			  </div>
		  </div>
	  </div>

	</div>

);

export default Hero
