import React from 'react'
import { withTranslation } from 'react-i18next';

class Hinnasto extends React.Component {

	render() {
		const { t, i18n } = this.props;
	/*	const sololistobject = t('component.hinnasto.listasololista', {returnObjects:true});
		const teamlistobject = t('component.hinnasto.listateamlista', {returnObjects:true});
		const sololist = Object.entries(sololistobject).map(([key, val], i) => (val && <li key={i}>{val}</li>));
		const teamlist = Object.entries(teamlistobject).map(([key, val], i) => (val && <li key={i}>{val}</li>));*/
		const solo = [
			t('component.hinnasto.listasololista.1'),
			t('component.hinnasto.listasololista.2'),
			];
		const team = [
			t('component.hinnasto.listateamlista.1'),
			t('component.hinnasto.listateamlista.2'),
			t('component.hinnasto.listateamlista.3'),
			t('component.hinnasto.listateamlista.4')
			];
		const free = [
			t('component.hinnasto.listafreelista.1'),
			t('component.hinnasto.listafreelista.2'),
			t('component.hinnasto.listafreelista.3'),
			t('component.hinnasto.listafreelista.4'),
			//t('component.hinnasto.listafreelista.5'),
			t('component.hinnasto.listafreelista.6'),
			t('component.hinnasto.listafreelista.7')
			];
		const sololist = solo.map((val, i) => (val && <li key={i}>{val}</li>));
		const teamlist = team.map((val, i) => (val && <li key={i}>{val}</li>));
		const freelist = free.map((val, i) => (val && <li key={i}>{val}</li>));
		return (
			<div className="component-Hinnasto">
			<section id="hinnasto" className="showcase_1 padding_top40">
			<div className="container nopadding text-center">
			{/*	<h2 className="font42 light font_second dark_gray">Hinnasto</h2>
				<div className="margin_auto top25 max_width770 font18 light text"><h2>Valitse yhden käyttäjän versio tai ota työkalu käyttöön koko tiimillesi</h2></div>*/}
				
				<div className="row cards_row">
				
				<div className="top25 text-left flex justify_between cards width_full max_width1171 margin_auto">
						{/*	<div className="margin_auto bottom60 max_width400 block text-center width_full">
									<div className="width_full text-center bottom30"><img srcSet="/images/duuers-1-3-x.png 1x, /images/duuers-1-3-x@2x.png 2x, /images/duuers-1-3-x@3x.png 2x" src="/images/duuers-2-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>
									<div className="component-Hinnasto__cardtitle font18 semibold font_second white title">
										Duuers FREE
									</div>
									<div className="semibold component-Hinnasto__cardpadding font16 light hyphens bg_light">
										Kevytyrittäjille ja satunnaisille duunareille!
									</div>
									<div className="component-Hinnasto__cardpadding font16 light hyphens bg_light_blue">
										1 käyttäjä
									</div>
									<div className="semibold component-Hinnasto__cardpadding bg_green font16 top25 white light hyphens">
										Pysyvästi ilmainen.<br /> Voi lähettää 20 tarjousta ja saat joka kuukausi 2 ilmaista lähetystä lisää.  
									</div>
								</div>
							*/}	
						<div className="margin_auto bottom60 max_width400 block text-center width_full bg_white90">
						{/*	<div className="width_full text-center bottom30"><img srcSet="/images/duuers-3-3-x.png 1x, /images/duuers-3-3-x@2x.png 2x, /images/duuers-3-3-x@3x.png 2x" src="/images/duuers-3-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>*/}
							<div className="component-Hinnasto__cardpadding bg_blue font26 semibold font_second white title">
							{t('component.hinnasto.listafree.title')}
							</div>
							<div className="bg_blue font32 font_second white title component-Hinnasto__cardpadding">
							{t('component.hinnasto.listafree.price')}
							</div>
							<div className="semibold component-Hinnasto__cardpadding font16 black hyphens bg_light">
								{t('component.hinnasto.listafree.subtitle')}
							</div>
							<div className="component-Hinnasto__cardpadding font16 black hyphens bg_white">
								{t('component.hinnasto.listafree.users')}
							</div>
							<div className="component-Hinnasto__cardpadding font16 black hyphens bg_light">
							
								<div className="semibold text-left component-Hinnasto__cardpadding font16 light hyphens bg_light"></div>
								<ul className="text-left bottom100">{freelist}</ul>
							</div>
								<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size40 width95 radius4 green semibold">
	          		            {t('component.hinnasto.listafree.start')}
	          		             </a>
						{/*	<div className="semibold component-Hinnasto__cardpadding bg_green top10 font16 white light hyphens">
							{t('component.hinnasto.listafree.start')}<br />
							{t('component.hinnasto.listafree.order')}<br />
							</div>*/}
						</div>
							
							
						<div className="margin_auto bottom60 max_width400 block text-center width_full bg_white90">
							{/*<div className="width_full text-center bottom30"><img srcSet="/images/duuers-2-3-x.png 1x, /images/duuers-2-3-x@2x.png 2x, /images/duuers-2-3-x@3x.png 2x" src="/images/duuers-1-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>*/}
							<div className="component-Hinnasto__cardpadding bg_blue font26 semibold font_second white title">
							{t('component.hinnasto.listasolo.title')}
							</div>
							<div className="bg_blue font32 font_second white title">
							{t('component.hinnasto.solohinta')}
							</div>
							<div className="padding_bottom10 bg_blue font14 font_second white title">
							{t('component.hinnasto.kkalv')}
							</div>
							<div className="semibold component-Hinnasto__cardpadding font16 black hyphens bg_light">
							{t('component.hinnasto.listasolo.subtitle')}
							</div>
							<div className="component-Hinnasto__cardpadding font16 black hyphens bg_white">
							{t('component.hinnasto.listasolo.users')}
							</div>
							<div className="component-Hinnasto__cardpadding top20 bottom20 font16 black hyphens bg_light">
							<div className="semibold text-left component-Hinnasto__cardpadding font16 light hyphens bg_light">Duuers FREE ominaisuuksien lisäksi…</div>
								<ul className="text-left bottom85">{sololist}</ul>
								
							</div>
							<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size40 width95 radius4 green semibold">
          		            {t('component.hinnasto.listasolo.start')}
          		             </a>
          		             <a href={t('component.hinnasto.listasolo.orderlinkkifi')} target="_blank" className="btn size40 width95 radius4 light_green semibold">
          		            {t('component.hinnasto.listasolo.order')}
          		             </a>
						{/*	<div className="semibold component-Hinnasto__cardpadding bg_green font16 top10 white light hyphens">
								{t('component.hinnasto.listasolo.start')}<br />
								{t('component.hinnasto.listasolo.order')}<br />
							</div> */}
						</div>
						
						<div className="margin_auto bottom60 max_width400 block text-center width_full bg_white90">
						{/*	<div className="width_full text-center bottom30"><img srcSet="/images/duuers-3-3-x.png 1x, /images/duuers-3-3-x@2x.png 2x, /images/duuers-3-3-x@3x.png 2x" src="/images/duuers-3-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>*/}
							<div className="component-Hinnasto__cardpadding bg_blue font26 semibold font_second white title">
							{t('component.hinnasto.listateam.title')}
							</div>
							<div className="bg_blue font32 font_second white title">
							{t('component.hinnasto.teamhinta')}
							</div>
							<div className="padding_bottom10 bg_blue font14 font_second white title">
							{t('component.hinnasto.kkalv')}
							</div>
							<div className="semibold component-Hinnasto__cardpadding font16 black hyphens bg_light">
								{t('component.hinnasto.listateam.subtitle')}
							</div>
							<div className="component-Hinnasto__cardpadding font16 black hyphens bg_white">
								{t('component.hinnasto.listateam.users')}
							</div>
							<div className="component-Hinnasto__cardpadding font16 black hyphens bg_light top20">
								<div className="semibold text-left component-Hinnasto__cardpadding font16 light hyphens bg_light">Duuers Solo ominaisuuksien lisäksi…</div>
								<ul className="text-left bottom55">{teamlist}</ul>
							</div>
							<a href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`} className="btn size40 width95 radius4 green semibold">
          		            {t('component.hinnasto.listateam.start')}
          		             </a>
          		             <a href={t('component.hinnasto.listateam.orderlinkkifi')} target="_blank" className="btn size40 width95 radius4 light_green semibold">
          		            {t('component.hinnasto.listateam.order')}
          		             </a>
						{/*	<div className="semibold component-Hinnasto__cardpadding bg_green top10 font16 white light hyphens">
							{t('component.hinnasto.listateam.start')}<br />
							{t('component.hinnasto.listateam.order')}<br />
							</div>*/}
						</div>
						{/*<div className="margin_auto bottom60 max_width400 block text-center width_full">
							<div className="width_full text-center bottom30"><img srcSet="/images/duuers-1-3-x.png 1x, /images/duuers-1-3-x@2x.png 2x, /images/duuers-1-3-x@3x.png 2x" src="/images/duuers-2-3-x.png" className="radius8 max_width370 max_height150" alt="" /></div>
							<div className="component-Hinnasto__cardpadding bg_blue font18 semibold font_second white title">
								Duuers UNLIMITED
							</div>
							<div className="semibold component-Hinnasto__cardpadding font16 light hyphens bg_light">
								Isoille organisaatioille.
							</div>
							<div className="component-Hinnasto__cardpadding font16 light hyphens bg_light_blue">
								Rajaton määrä käyttäjiä
							</div>
							<div className="component-Hinnasto__cardpadding top10 font16 light hyphens bg_light">
								<div className="semibold text-left component-Hinnasto__cardpadding top10 font16 light hyphens bg_light">Kuten Duuers Team, lisäksi…</div>
								<ul className="text-left">
									<li>
										Rajaton käyttäjämäärä.
									</li>
									<li>
										Integraatiot CRM-järjestelmiin.
									</li>
								</ul>
							</div>
							<div className="semibold component-Hinnasto__cardpadding bg_green font16 top10 white light hyphens">
								Pyydä tarjous.&nbsp; <a className="blue" href="#">Lomakkeelle →</a>
							</div>
						</div> */}
						
					</div>
					
				</div>
				{/*	<div className="margin_auto max_width600 inner bg_white90 padding_top10 padding_bottom10">
						<div className="font18 light dark_blue hyphens text top30">Tarvitsetko apua tarjouspohjan suunnitteluun, ota yhteyttä asiakaspalveluumme.</div>
						<div className="font18 light dark_blue hyphens text top30"><a href="+358 10 841 5654"><i className="fa fa-phone blue"></i>+358 10 841 5654</a></div>
						<div className="top20"><a className="blue" href="mailto:tuki@duuers.com">tuki@duuers.com</a></div>
					</div> */}
				</div> 
			</section>
        </div>);
	}
};

export default withTranslation()(Hinnasto);
