import React from 'react'
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import 'slick-carousel';

export function Reference(props){
  const { children, name, title, company, image } = props
  return(
    <div className="padding_top25 component-Reference padding_sides60">
  		<div className="radius8 slide bg_white">
  			{image &&
  			  <div className="component-Reference__image" ><img src={image} alt="" /></div>
  			}
  			<div className="inner padding_top40 padding_bottom40 padding_sides60 component-Reference__text">
  				<div className="font22 light dark_blue text">
  					{children}
  				</div>
  				<div className="top30 bold">{name}</div> <div>{title}</div>
  				<div className="dark_blue opacity3">{company}</div>
  			</div>
  		</div>
  	</div>
  )
}

class References extends React.Component {
  componentDidMount() {
    $(".content_31 .slider").slick({
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 20000,
      responsive: [{
        breakpoint: 991,
        settings: {
          arrows: false
        }
      }]
    });
  }
  render() {
    const { 
     // t, 
      children } = this.props;
    return (
      <section  id="references" className="content_31 padding_top0 padding_bottom20 component-References">
      	<div className="container nopadding">
      	{/*		<h2 className="font_second light font42 dark_gray text-center">
      	{t('component.references.title')} 
      		</h2>*/}
      		<div className="top50 slider arrows0 dots_dark_blue dots-50">
      			{children}
      		</div>
      	</div>
      </section>);
  }
}

export default withTranslation()(References);
