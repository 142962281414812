import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import '../styles/style.scss';

class DownloadLayout extends React.Component {

  componentDidMount() {
    if (this.props.language) {
      this.props.i18n.changeLanguage(this.props.language);
    }
  }

  render() {
    const { t, i18n, title, meta, link, children } = this.props;
    return (<div className="layout-download">
    <Helmet
      title={title}
      meta={meta}
      link={link}
    >
    </Helmet>
    <div className="blue">
      <header className="padding_left125 padding_right125">
      	<div className="transparent padding_top60 padding_bottom30">
      		<div className="container nopadding line_height50">
      			<div className="row">
      				<div className="col-6 blue font20 line_height50 logo"><a href={`https://www.duuers.com/?lng=${i18n.language}`}><img src="/images/duuers-logo.svg" alt="logo"/></a></div>
      				<div className="col-6 font20 line_height50">
      				<a href="javascript:history.back()" className="float_right">{t('page.download.back')}</a>
      				</div>
      			</div>
      		</div>
      	</div>
      </header>
    </div>
    {children}
  </div>)
  }
};

DownloadLayout.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
  link: PropTypes.array,
  language: PropTypes.string
};

export default withTranslation()(DownloadLayout);
