import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CookieBanner from 'react-cookie-banner';
import { withTranslation } from 'react-i18next';
import Header from '../components/Header';
import StickyCTA from '../components/StickyCTA';
import '../styles/style.scss';

const WebLayout = ({ t, i18n, title, meta, link, children, alwaysSticky }) => {
  return (<div>
    <CookieBanner disableStyle={true} 
                  buttonMessage="OK" 
                  link={{msg: t('cookie.policy.link'), url: '/privacy'}}
                  message={t('cookie.policy.title')}
                  onAccept={() => {}}
                  cookie='user-has-accepted-cookies'/>
    <Helmet
      title={title}
      meta={meta}
      link={link}
    >
    </Helmet>
    <Header alwaysSticky={alwaysSticky}/>
    {children}
    <StickyCTA/>
  </div>)
};

WebLayout.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
  link: PropTypes.array
};

export default withTranslation()(WebLayout);
