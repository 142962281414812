import React from 'react'

const HeroPricing = ({children}) => (
  <div className="padding_top200 padding_bottom60 min_height800 component-HeroPricing">
	  <div className={"container-fluid line_height50 center"}>
			  <div className="white text-center">
				{children}
			  </div>
	  </div>

	</div>

);

export default HeroPricing
