import React from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";

class StickyCTA extends React.Component {
  showStickyCTA() {
    if (
      $(window).width() < 1200 &&
      $(window).scrollTop() > $(window).height() &&
      $(window).scrollTop() + $(window).innerHeight() <= $(document).height() - 150
    ) {
      $(".component-Sticky-CTA").addClass("visible");
      $(".component-Sticky-CTA").removeClass("hidden");
    } else {
      $(".component-Sticky-CTA").addClass("hidden");
      $(".component-Sticky-CTA").removeClass("visible");
    }
  };

  componentWillUnmount() {
    $(window).off("resize", this.showStickyCTA.bind(this));
    $(document).off("scroll", this.showStickyCTA.bind(this));
  }

  componentDidMount() {
    $(window).on("resize", this.showStickyCTA.bind(this));
    $(document).on("scroll", this.showStickyCTA.bind(this));
    $(window).trigger("resize");
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <div className="component-Sticky-CTA radius6">
        <a
          href={`https://app.duuers.com/auth/signup?lng=${i18n.language}`}
          className="btn green size60 padding_sides35 radius6 semibold"
        >
          {t("component.hinnasto.listafree.start")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(StickyCTA);
