import React from "react"
//import { Link } from "gatsby"

import {Footer, Header} from '../components';
import '../styles/style.scss';

class BlogLayout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div> 
        <Header alwaysSticky={true}/>
        <main>{children}</main>
        <Footer/>
      </div>
    )
  }
}

export default BlogLayout
